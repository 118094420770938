import React from "react";
import music from "../images/music.png"
import ReactAudioPlayer from 'react-audio-player';
import muz from "../video/muz.mp3"
import { ParallaxProvider } from 'react-scroll-parallax';
import '../css/Timer.css';
import '../css/Body.css';
import Timer from "./Timer";
import Parallaximg from "../Parallaximg";

import PhoneIphoneIcon from '@mui/icons-material/PhoneIphone';
import MessageIcon from '@mui/icons-material/Message';
import { WhatsApp } from "@mui/icons-material";


import CardLocal from "../cardLocal";


import FormSection from "../FormSection";

import BlogSectionNoi from "../blogSectionNoi";
import BlogSectionNasii from "../blogSectionNasii";
import data from "../Data";
import CardInvitatie from "./CardInvitatie";



const Home = (props) => {


    return (



        <ParallaxProvider>

            {data.introData.map((item) => {
                return (
                    <Parallaximg imgsrc={window.innerWidth >= 850 ? item.imgdesktop : item.imgmobil} height="900px" opacity=".5" >
                        <div id="header" className="row text-center align-items-center" style={{ position: 'absolute' }}>
                            <div className="col-12 ">
                               <span>
    <img className="music animate__animated animate__pulse animate__delay-4s animate__infinite" src={music}/>
    <audio src={muz} controls autoPlay loop ></audio>
</span>
       
                                <h1 style={{ fontSize: '60px', textShadow: `1px 1px 2px black, 0 0 .5em grey, 0 0 1em darkgreen` }}>{item.mire} & {item.mireasa}</h1>
                                                         <h4 style={{ fontSize: '36px' }}>{item.savedata}<br />{item.data}</h4>
                                <Timer />

                                <h4 className="mt-5 p-2" style={{ fontSize: '25px', textShadow: `1px 1px 2px black, 0 0 .5em grey, 0 0 1em darkgreen` }}>"Căsnicia este o artă ce trebuie <br /> să o creezi în fiecare zi" - R. Tagore</h4>
                            </div>

                        </div>
                    </Parallaximg>
                )
            })}





            {/* 
            {data.blogDataDrum.map((item) => {
                return (
                    <Parallaximg imgsrc={window.innerWidth >= 850 ? item.imgdesktop : item.imgmobil} height="400px" opacity=".5">
                        <div className="row text-center align-items-center" style={{ position: 'absolute' }}>
                            <div className="col-12 " style={{ maxWidth: '480px', padding: '20px' }}>
                                <h1 style={{ fontSize: '35px' }}>{item.title}</h1>
                                <h4 >{item.message}</h4>
                            </div>
                        </div>
                    </Parallaximg>
                )
            })}
*/}


            <CardInvitatie />





            {data.blogDataOmSuflet.map((item) => {
                return (
                    <Parallaximg imgsrc={item.img} height="400px" opacity=".5">
                        <div className="row text-center align-items-center" style={{ position: 'absolute' }}>
                            <div className="col-12 " style={{ maxWidth: '480px', padding: '20px' }}>
                                <h1 style={{ fontSize: '35px' }}>{item.title}</h1>
                                <h4 >{item.message}</h4>
                                <h4 >{item.message1}</h4>
                                {/*<audio src={muz} controls autoPlay loop ></audio> */}
                               


                            </div>
                        </div>
                    </Parallaximg>
                )
            })}






            <CardLocal />



            {/*  <FormSection />*/}


            {data.introData.map((item) => {
                return (
                    <div>
                        <h4 className="text-center mt-5" >
                            Vă așteptăm cu drag!
                        </h4>
                        <h3 className="text-center" style={{ fontFamily: 'Dancing Script', fontSize: '30px' }}>
                            Ion & Diana
                        </h3>
                        <h5 className="text-center animate__animated animate__pulse animate__delay-4s animate__infinite">
                            <svg width="1.5em" height="1.5em" color="red" viewBox="0 0 16 16" class="bi bi-suit-heart-fill"
                                fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                <path
                                    d="M4 1c2.21 0 4 1.755 4 3.92C8 2.755 9.79 1 12 1s4 1.755 4 3.92c0 3.263-3.234 4.414-7.608 9.608a.513.513 0 0 1-.784 0C3.234 9.334 0 8.183 0 4.92 0 2.755 1.79 1 4 1z" />
                            </svg>
                            <br />
                        </h5>
                        <h3 className="text-center mb-5" style={{ fontFamily: 'Dancing Script', fontSize: '30px' }}>
                            {item.mire} & {item.mireasa}
                        </h3>

                        <div className="text-center mb-5">
                            Abia așteptăm să vă vedem! <br /> Dacă aveți orice altă întrebare,  vă rugăm să ne contactați sau
                            scrieți-ne un mesaj:<br />
                            Diana {item.tel} : <br /><a class=" m-2 btn_logo" href={item.phone}> <PhoneIphoneIcon /> Phone</a>
                            {/*<a className="m-2 btn_logo" href={item.viber}> <PhoneIcon />Viber</a>*/}
                            <a className="m-2  btn_logo" href={item.whatsapp}><WhatsApp />WhatsApp</a>
                            <a className=" m-2  btn_logo" href={item.messenger}><MessageIcon />Messenger</a><br />
                        </div>

                    </div>

                )
            })}





        </ParallaxProvider>



    )
}

export default Home;