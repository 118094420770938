import React from "react"
import "../css/ImgInvitatie.css"
import noi from "../images/nasi.jpeg"
import muz from "../video/muz.mp3"
import music from "../images/music.png"

const CardInvitatie = () => {
    return (

        <section id="invitatie" className="text-center mt-5" >
            <h1>Invitație</h1>
            <div className="card card-invitation text-center col-lg-6 col-sm-10  m-auto mb-4">
                <div className="card p-0 overflow-hidden h-100 shadow">


                    <div class="card-body">
                        <div className="text mb-3">
                        <h5>DIN SINCERĂ ȘI PROFUNDĂ STIMĂ</h5>
                            <h5>NOI NAȘII,</h5>

                            <img id="img_noi" src={noi} />
                            <h4 className="pt-2">Ion & Diana</h4>
                            <h5>
                                VĂ INVITĂM SĂ LUAȚI PARTE LA CELEBRAREA <br/>
                                CĂSĂTORIEI FINILOR NOȘTRI,
                            </h5>
                            <h4 className="pt-2">Anatolie & Olga</h4>
                            <h5>
                                care va avea loc:
                            </h5>
                            <h4>
                               vineri, 17 iunie 2022
                            </h4>

                            <h5>
                                în incinta Restaurantului<br />
                              
                            </h5>
                         
                            <h4>
                                ”La Madonnina di Barni”, ora 16:30,
                            </h4>
                            <h5 className="">
                            via per la madonnina, 22030 Barni CO, Italia
                            </h5>


                            

                        </div>










                        <h4 class="card-footer">Vă așteptăm cu drag!</h4>

                       <a href="#geolocal" className="btn btn-primary">Vezi Locația</a>

                    </div>




                </div>




            </div>


        </section>

    )
}

export default CardInvitatie;