import restaurant from "./images/Madonnina.jpg";
import img_card from "./images/nasi.jpeg";
import imgOmSuflet from "./images/1.jpeg";
import imgheader from "./images/123.webp";
import imgheadermiini from "./images/h11.jpg";
import imgheadermiinimb from "./images/h11.jpg";

{/*valeriacosarca@gmail.com*/}

const data = {
    introData: [{
        title:"Invitatie Online - Ion & Diana",
        mire: "Anatolie",
        mireasa: "Olga",
        data: "17 Iunie 2022",
        data_confirmare: "2 iunie 2022",
        savedata: "~ Se căsătoresc! ~",
        imgdesktop: imgheader,
        imgmobil: imgheader,
        email: "anusic007@gmail.com", 
       tel: "+39 380 432 3690",
       phone: "tel:+393804323690",
       viber: "viber://chat?number=%2B393804323690",
       whatsapp: "https://wa.me/+3393804323690",
       messenger: "https://www.messenger.com/t/diana.rosca.585",
    }],
    cardData: [
        {
            id: 1,
            img: restaurant,
            title: "Locația Evenimentului",
            name: "La Madonnina di Barni",
            data: "17 iunie 2022, vineri, ora 16:30",
            adress: "via per la madonnina, 22030 Barni CO, Italia",
            harta: "https://goo.gl/maps/xpKUHRLv7D5cmJPy5",
            iframe: "https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d11105.610370783215!2d9.2797921!3d45.9032601!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x90a210e235f2b2bd!2sRistorante%20La%20Madonnina%20di%20Barni!5e0!3m2!1sro!2s!4v1652163462314!5m2!1sro!2s",
        }

    ],
    blogDataNoi: [
        {
            id: 1,
            img: img_card,
            title1: "Ne căsătorim!",
            title2: "VĂ INVITĂM LA NUNTA NOASTRĂ!",
            message: "În ziua în care ne vom uni destinele, vrem să fim înconjurați de cei mai apropiați și dragi oameni nouă. În acest context avem plăcerea de a vă invita să ne fiți martori și să împărtășiți bucuria celui mai semnificativ eveniment din viața noastră.",
        }

    ],
    blogDataNasii: [
        {
            id: 2,
            img: "",
            title1: "Invitație",
            title2: "Nunta noastră!",
            message: "In ziua in care ne vom uni destinele, vrem să fim înconjurați de cei mai apropiați și dragi oameni nouă. În acest context avem plăcerea de a va invita să ne fiți martori și să împărtășiți bucuria celui mai semnificativ eveniment din viața noastră.",
        }
    ],
    blogDataOmSuflet: [
        {

            img: imgOmSuflet,
            title: "P.S. Nu uităm să...",
            message: "# Venim încărcați la maxim pozitivi!",
            message1: "# Avem pantofi comozi pentru că dansurile până în zori sunt garantate!",
            parintii_nume1: "Vasile & Diana",
            parintii_nume2: "Ghenadie & Oxana",
            nasii: "ȘI SUSȚINEREA NAȘILOR",
            nasii_nume: "Vasile & Svetlana",
        }
    ],
    blogDataDrum: [
        {
            imgdesktop: imgheadermiini,
            imgmobil: imgheadermiinimb,
            title: "\"Căsnicia este o artă ce trebuie să o creezi în fiecare zi\"",
            message: " – R. Tagore",
        }
    ],

}

export default data;